import { astFromValue } from "graphql"
import React, { createContext, useContext, useEffect, useState } from "react"

const CartOpenContext = createContext(false)

export const CartOpenContextProvider = ({ children }) => {
  const [cartOpen, setCartOpen] = useState(false)
  const [cartDetails, setCartDetails] = useState({})
  const [cartCount, setCartCount] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)
  const [formattedTotalPrice, setFormattetTotalPrice] = useState(0)

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("cartStorage"))) {
      setCartDetails(JSON.parse(localStorage.getItem("cartStorage")))
    }
  }, [])

  useEffect(() => {
    setCartCount(Object.keys(cartDetails).length)
    let totalpriceCopy = Object.entries(cartDetails)
      .map(([key, val]) => {
        return cartDetails[key]["value"]

        // return key[value]
      })
      .reduce((a, b) => Number(a) + Number(b), [0])

    setTotalPrice(totalpriceCopy)
    setFormattetTotalPrice(
      `${(totalpriceCopy / 100).toLocaleString("de", {
        minimumFractionDigits: 2,
      })} €`
    )
    localStorage.setItem("cartStorage", JSON.stringify(cartDetails))
  }, [cartDetails])

  return (
    <CartOpenContext.Provider
      value={[
        cartOpen,
        setCartOpen,
        cartDetails,
        setCartDetails,
        cartCount,
        totalPrice,
        formattedTotalPrice,
      ]}
    >
      {children}
    </CartOpenContext.Provider>
  )
}
export const useCartOpen = () => {
  const [
    cartOpen,
    setCartOpen,
    cartDetails,
    setCartDetails,
    cartCount,
    totalPrice,
    formattedTotalPrice,
  ] = useContext(CartOpenContext)

  const handleCartOpen = (value) => setCartOpen(value)

  const addItem = (value) => {
    const cartItem = {}
    cartItem[value.id] = value
    cartItem[value.id]["formattedPrice"] = `${(
      value.price / 100
    ).toLocaleString("de", {
      minimumFractionDigits: 2,
    })} €`
    cartItem[value.id]["value"] = value.price
    cartItem[value.id]["quantity"] = 1

    setCartDetails({ ...cartDetails, ...cartItem })
  }

  const removeItem = (value) => {
    let cartDetailsCopy = cartDetails
    delete cartDetailsCopy[value]
    setCartDetails({ ...cartDetailsCopy })
  }
  const clearCart = () => {
    setCartDetails({})
  }

  // console.log("CartOpenContext", CartOpenContext)

  return {
    cartOpen: cartOpen,
    setOpenCart: handleCartOpen,
    cartDetails: cartDetails,
    addItem: addItem,
    cartCount: cartCount,
    removeItem: removeItem,
    totalPrice: totalPrice,
    formattedTotalPrice: formattedTotalPrice,
    clearCart: clearCart,
  }
}

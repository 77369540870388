import React, { createContext, useContext, useState } from "react"

const ReferrerContext = createContext(null)

export const ReferrerContextProvider = ({ children }) => {
  const [referrer, setReferrer] = useState({ referrer: "none", search: "none" })
  return (
    <ReferrerContext.Provider value={[referrer, setReferrer]}>
      {children}
    </ReferrerContext.Provider>
  )
}
export const useReferrer = () => {
  const [referrer, setReferrer] = useContext(ReferrerContext)

  const handleReferrer = (value) => setReferrer(value)

  return { referrer: referrer, setReferrer: handleReferrer }
}

import React from "react"

import { CartOpenContextProvider } from "./src/components/context/cartOpenContext"
import { ReferrerContextProvider } from "./src/components/context/referrerContext"

export const wrapRootElement = ({ element }) => (
  <ReferrerContextProvider>
    <CartOpenContextProvider>{element}</CartOpenContextProvider>
  </ReferrerContextProvider>
)
